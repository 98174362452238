<template>
    <v-row justify="center">
      <v-dialog v-model="show" width="600px" scrollable persistent>
        <v-card>
          <v-card-title>
            <span class="text-h5">ข้อกำหนดและเงื่อนไข</span>
          </v-card-title>
          <v-card-text>
                consent register
            Lorem ipsum dolor sit amet, semper quis, sapien id natoque elit.
            Nostra urna at, magna at neque sed sed ante imperdiet, dolor mauris
            cursus velit, velit non, sem nec. Volutpat sem ridiculus placerat leo,
            augue in, duis erat proin condimentum in a eget, sed fermentum sed
            vestibulum varius ac, vestibulum volutpat orci ut elit eget tortor.
            Ultrices nascetur nulla gravida ante arcu. Pharetra rhoncus morbi
            ipsum, nunc tempor debitis, ipsum pellentesque, vitae id quam ut
            mauris dui tempor, aptent non. Quisque turpis. Phasellus quis lectus
            luctus orci eget rhoncus. Amet donec vestibulum mattis commodo, nulla
            aliquet, nibh praesent, elementum nulla. Sit lacus pharetra tempus
            magna neque pellentesque, nulla vel erat. Justo ex quisque nulla
            accusamus venenatis, sed quis. Nibh phasellus gravida metus in, fusce
            aenean ut erat commodo eros. Ut turpis, dui integer, nonummy pede
            placeat nec in sit leo. Faucibus porttitor illo taciti odio, amet
            viverra scelerisque quis quis et tortor, curabitur morbi a. Enim
            tempor at, rutrum elit condimentum, amet rutrum vitae tempor torquent
            nunc. Praesent vestibulum integer maxime felis. Neque aenean quia
            vitae nostra, tempus elit enim id dui, at egestas pulvinar. Integer
            libero vestibulum, quis blandit scelerisque mattis fermentum nulla,
          </v-card-text>
          <v-card-actions>
            <v-layout row wrap justify-center>
              <v-flex lg8 xs8>
                  <v-checkbox v-model="check_accept" color="primary" class="text-center">
                      <template v-slot:label>
                          <v-card-text class="pa-0" @click.stop="">
                              ฉันได้อ่านและยินยอมรับข้อกำหนดและเงื่อนไข
                          </v-card-text>
                      </template>
                  </v-checkbox>
  
              </v-flex>
              <v-flex lg6 xs6>
                <v-btn class="white--text" color="green darken-1" block :disabled="!check_accept" @click="fn_consent()">
                  Agree
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <script>
  import { mapState, mapGetters } from "vuex";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import gbfGenerate from "@/globalFunctions/generateAuthorize";
  import VueCookies from "vue-cookies";
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  export default {
    data: function() {
      return {
          check_accept:false,
      };
    },
    computed: {
      ...mapState([
        "username",
        "authorize",
        "account_active",
        "color",
        "role_level",
      ]),
      ...mapState({ processloader: "loading" }),
      ...mapGetters([
        "dataUsername",
        "dataAuthorize",
        "dataAccountActive",
        "dataAccesstoken",
        "dataBusinessProfile",
        "dataCitizenProfile",
        "dataDepartmentAccessId",
        "dataAccountId",
      ]),
    },
    props: ["show"],
    methods: {
      fn_consent(){
          this.$emit('register')
      }
    },
  };
  </script>
  <style></style>
  